import React, { FunctionComponent } from "react";
import { Link } from "gatsby";

import * as classes from "./header.module.scss";

interface HeaderProps {
  backUrl?: string;
  backText?: string;
}

const Header: FunctionComponent<HeaderProps> = ({ backUrl, backText }) => (
  <div>
    <h1 className={classes.header}>
      <Link to="/">Nino Desirelli</Link>
    </h1>
    {backUrl && (
      <Link className={classes.back} to={backUrl}>
        &#8592; {backText ?? "Back"}
      </Link>
    )}
  </div>
);

export default Header;
