import React, { FunctionComponent } from "react";

import Header from "./header";

import "../../main.scss";
import * as classes from "./layout.module.scss";

interface LayoutProps {
  vertical?: boolean;
  backUrl?: string;
  backText?: string;
}

const Layout: FunctionComponent<LayoutProps> = ({
  vertical = false,
  backUrl,
  backText,
  children,
}) => {
  const verticalClass = vertical ? classes.vertical : "";

  return (
    <div className={`${classes.layout} ${verticalClass}`}>
      <header className={classes.header}>
        <Header backUrl={backUrl} backText={backText} />
      </header>
      <main className={classes.main}>{children}</main>
    </div>
  );
};

export default Layout;
